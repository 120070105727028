import React, { useState } from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:perezalejandra.e@gmail.com?subject=${encodeURIComponent(
      formData.subject
    )}&body=${encodeURIComponent(formData.message)}`;

    window.open(mailtoLink, "_blank");

    setFormData({
      email: "",
      subject: "",
      message: "",
    });
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <Navigation />

      <div className="flex-grow flex flex-col justify-center mt-16">
        <div className="mx-auto p-4 max-w-screen-md tablet:w-4/5 laptop:my-10 desktop:my-10">
          <h1 className="text-center rounded-full bg-aquamarine my-4 py-2 text-4xl font-extrabold text-majorelleBlue-500 tablet:text-5xl laptop:text-6xl desktop:text-7xl">
            Let's build together.
          </h1>
          <p className="font-light text-center text-majorelleBlue-500 phone:text-xl tablet:text-lg laptop:text-lg desktop:text-lg">
            Whether you're a startup looking to improve user experience or a
            large coporation looking to redesign its digital assets, I'm someone
            who can help you achieve your goals!
          </p>
          <form className="space-y-8 mt-10" onSubmit={handleSubmit}>
            <div>
              <label
                for="subject"
                className="block mb-2 text-md font-semibold text-majorelleBlue-500"
              >
                Subject
              </label>
              <input
                type="text"
                name="subject"
                onChange={handleChange}
                value={formData.subject}
                className="block p-3 w-full text-sm rounded-lg border border-2 border-majorelleBlue-500 focus:ring-majorelleBlue-500"
                placeholder="Let me know how I can help you..."
                required
              />
            </div>
            <div className="phone:col-span-2">
              <label
                for="message"
                className="block mb-2 text-md font-semibold text-majorelleBlue-500"
              >
                Your message
              </label>
              <textarea
                name="message"
                rows="6"
                onChange={handleChange}
                value={formData.message}
                xs
                className="block p-3 w-full text-sm rounded-lg border border-2 border-majorelleBlue-500 focus:ring-majorelleBlue-500 hover:cursor-text"
                placeholder="Leave your thoughts here..."
              ></textarea>
            </div>
            <button
              type="submit"
              className="py-3 px-5 text-sm font-semibold text-center text-majorelleBlue-500 shadow-lg shadow-majorelleBlue-500/50 rounded-full bg-jasmine-700 phone:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-majorelleBlue-500 hover:bg-majorelleBlue-500 hover:text-jasmine-700"
            >
              Send message
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactPage;
