// src/components/AboutPage.jsx
import React, { useState, useEffect } from "react";
import Navigation from "./Navigation";
import ProfilePicture from "../images/1566525314066.jpeg"; // Adjust the path as necessary
import Experience from "./Experience";
import { Link } from "react-router-dom";
import Footer from "./Footer";

const AboutPage = () => {
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    console.log(hasScrolled);

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToWorkExperience = () => {
    // Using document.querySelector to get the section element by ID
    const workExperienceElement = document.querySelector("#work-experience");

    // Using scrollIntoView to scroll to the section
    workExperienceElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-grow tablet:mx-10">
        <Navigation />

        <div className="laptop:flex desktop:flex xxl:flex">
          <div className="flex flex-col justify-center items-center laptop:w-2/5 laptop:sticky desktop:w-2/5 desktop:sticky xxl:w-2/5 xxl:sticky top-0 h-screen p-4 tablet:hidden">
            <img
              className="rounded-full w-96 h-96 filter brightness-125 tablet:w-72 tablet:h-72 laptop:w-80 laptop:h-80 xxl:w-[500px] xxl:h-[500px]"
              src={ProfilePicture}
              alt="Alejandra Perez's Profile Picture"
            />

            {!hasScrolled && (
              <div
                onClick={scrollToWorkExperience}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-96 z-20 tablet:top-1/3 laptop:top-1/3 desktop:top-1/3"
              >
                <span>
                  <svg
                    className="animate-bounce w-32 h-32 text-majorelleBlue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            )}
          </div>

          <div className="overflow laptop:w-3/5 laptop:px-10 desktop:w-3/5 desktop:px-10 xxl:w-3/5 xxl:px-10">
            <section className="mt-20 flex flex-col p-2 tablet:p-6 desktop:w-11/12">
              <h1 className="text-majorelleBlue-500 mb-8 text-right	font-extrabold leading-relaxed tablet:text-center tablet:leading-none tablet:text-6xl laptop:text-6xl desktop:text-6xl xxl:text-8xl">
                Hello there! I'm{" "}
                <span className="bg-peach rounded-full py-1 px-4 mb-2 inline-flex items-center justify-center">
                  Alé
                </span>
                . I'm a{" "}
                <span className="bg-melon rounded-full py-2 px-4 mr-4 mb-2 inline-flex items-center justify-center ">
                  full-stack{" "}
                </span>
                <span className="bg-aquamarine rounded-full py-2 px-4 mb-2 inline-flex items-center justify-center">
                  developer{" "}
                </span>{" "}
                by day, and a{" "}
                <span className="bg-blue rounded-full py-2 px-4 inline-flex items-center justify-center tablet:mt-2">
                  creative{" "}
                </span>{" "}
                owl by night.
              </h1>

              <div className="flex flex-col justify-center items-center mb-8 laptop:hidden desktop:hidden xxl:hidden laptop:sticky top-0">
                <img
                  className="rounded-full w-96 h-96 filter brightness-125 tablet:w-72 tablet:h-72 laptop:w-72 laptop:h-72"
                  src={ProfilePicture}
                  alt="Alejandra Perez's Profile Picture"
                />

                {!hasScrolled && (
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-96 z-20 tablet:top-1/3 laptop:top-1/3">
                    <span>
                      <svg
                        className="animate-bounce w-32 h-32 text-majorelleBlue-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                )}
              </div>

              <p className="text-justify text-xl font-normal text-majorelleBlue-500 laptop:pr-4 laptop:text-xl xxl:text-3xl">
                In the digital realm, I weave code like a wizard, turning
                complex problems into elegantly simple solutions. My keyboard is
                my wand, and with each keystroke, I bring functionality and
                efficiency to life. But wait, there's more to me than just 1s
                and 0s! When I'm not busy debugging or deploying, you can find
                me exploring the wild frontiers of creativity. Whether it's
                creating a new spotify playlist, writing in my journal about my
                future aspirations, or doodling in my sketchbook, I believe in
                adding a splash of color to everything I do.
              </p>
              <br></br>
              <p className="text-justify text-xl font-normal text-majorelleBlue-500 laptop:pr-4 laptop:text-xl xxl:text-3xl">
                My journey through the tech landscape is fueled by endless
                curiosity and a hearty appetite for learning. From the
                nitty-gritty of back-end development to the finesse of front-end
                design, I love all things tech. But it's not just about the
                code; it's about the stories we can tell and the experiences we
                can create through technology.
              </p>
              <br></br>
              <p className="text-justify text-xl font-normal text-majorelleBlue-500 laptop:pr-4 laptop:text-xl xxl:text-3xl">
                So, if you're looking for someone who can code with precision
                and brainstorm with imagination, you're in the right place.
                Let's blend logic with creativity and see where this adventure
                takes us!
              </p>
            </section>

            <section
              id="work-experience"
              className="flex flex-col tablet:p-6 desktop:w-11/12"
            >
              <h1 className="text-majorelleBlue-500 mb-8 text-right	font-extrabold leading-relaxed tablet:text-6xl laptop:text-6xl desktop:text-6xl xxl:text-8xl">
                Work Experience.
              </h1>
              <p className="text-justify text-xl font-normal text-majorelleBlue-500 laptop:text-xl xxl:text-3xl">
                A curated showcase of my expertise as a developer. It's a
                glimpse into the breadth of my skills and creativity, offering a
                preview of the innovative solutions and engaging experiences I
                am capable of crafting.
              </p>
              <Experience />
            </section>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutPage;
