import React, { useState, useEffect } from "react";
import Card from "./Card";
import Footer from "./Footer";
import Navigation from "./Navigation";
import D3Image from "../images/graph.png";
import BudgetImage from "../images/budget.png";
import DesignImage from "../images/illustration.png";
import MarioAnimationImage from "../images/animate.png";
import ScheduleUtilizationImage from "../images/project.png";
import AdvancedAnalyticsImage from "../images/analytics.png";
import RockPaperScissorsImage from "../images/rock-paper-scissors.png";

const HomePage = () => {
  const [showContent, setShowContent] = useState(false);
  const [isBlurred, setIsBlurred] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null); // todo: do you really need this? test after
  const [activeIndex, setActiveIndex] = useState(0);
  const translateValue = -(activeIndex * 100);
  const [hasScrolled, setHasScrolled] = useState(false);
  const colors = ["bg-aquamarine", "bg-melon", "bg-peach", "bg-blue"];

  const cardData = [
    {
      title: "Schedule Utilization",
      img: ScheduleUtilizationImage,
      summary:
        '<h3 class="subheading">Enhancing efficiency for Paid Analytics Users </h3>' +
        "The Schedule Utilization feature was ingeniously crafted for paid analytics users, aimed at enhancing their ability to efficiently monitor and analyze how their employees allocate time during a workday or week. This innovation primarily serves to facilitate more accurate forecasting of future staff availability." +
        "</br> </br>" +
        '<h3 class="subheading">Intuitive Integration and Accessibility</h3>' +
        "At the core of its functionality, the feature is intuitively integrated into the scheduling page. Users encounter a prominent Utilization button positioned strategically at the top right corner of the calendar. Upon interacting with this button, a modal springs to life, revealing an insightful Looker graph. This graph is not merely a visual treat but a functional marvel, clearly delineating the distribution of an employee's busy and available time slots across the selected time frame, be it a day or a week." +
        "</br> </br>" +
        '<h3 class="subheading">Behind the Scenes: Development and Research</h3>' +
        "The development journey of this feature was marked by extensive research, delving into the intricacies of integrating Looker API calls within embedded iframes. The team navigated the complexities of ensuring seamless functionality in conjunction with older blade files, all while meticulously addressing any potential formatting issues that might arise in LookML. This rigorous research and careful consideration in development underscore the team's commitment to delivering a feature that is not only technologically sound but also user-centric, significantly simplifying the task of schedule management for analytics users.",
      slogan:
        "Revolutionizing Time Management: Explore the Power of Schedule Utilization for Smarter Analytics and Enhanced Employee Insight",
      date: "May 2023",
      company: "Coconut Software",
      team: "Product Manager, UX Designer, Senior Developer, Junior Developer",
      technologies: "React, PHP, Laravel, JavaScript, LookML, Figma",
      url: "https://github.com/ae-perez/aevp-portfolio/blob/main/src/images/schedule.png?raw=true",
    },
    {
      title: "Advanced Analytics",
      img: AdvancedAnalyticsImage,
      summary:
        '<h3 class="subheading">Introducing Advanced Analytics</h3>' +
        "The 'Advanced Analytics' feature, a premium offering from our software suite, is tailored for clients seeking a deeper, more comprehensive understanding of their data. This feature becomes accessible upon subscription, with a dedicated button appearing prominently on the main navigation bar." +
        "</br> </br>" +
        '<h3 class="subheading">Seamless Integration with Google BI</h3>' +
        "Engaging with this button transports users to a specialized page, seamlessly incorporating Google's BI tool within an iframe. This integration forms the core of the Advanced Analytics experience, offering users an array of pre-created dashboards, explores, and looks. These tools are meticulously designed to present a diverse range of statistics in a visually captivating and intuitive format, enabling users to glean valuable insights effortlessly. One of the standout aspects of this feature is its customization capability." +
        "Customization at Your Fingertips" +
        "Users are not limited to predefined data visualizations; they have the freedom to craft their own dashboards, explores, and looks. This personalization extends further, allowing users to save their custom creations for easy access from the main Advanced Analytics page. This level of user autonomy and flexibility underscores our commitment to providing a versatile and user-centric analytical tool." +
        "</br> </br>" +
        '<h3 class="subheading">Real-Time Analytics: The Journey So Far</h3>' +
        "While delivering real-time analytics posed a challenge, our team ingeniously developed an algorithm that ensures data is updated with only a minimal delay, typically just a few minutes. This near real-time capability represents a significant stride in data accuracy and timeliness." +
        "</br> </br>" +
        '<h3 class="subheading">The Future of Analytics</h3>' +
        "Looking ahead, one of our ambitious goals is to evolve this feature to offer real-time analytics. This enhancement aims to mirror the dynamic nature of each bank's data, providing an even more immediate and accurate reflection of current metrics. The pursuit of this goal highlights our ongoing commitment to innovation and excellence in data analysis, continually seeking to elevate the user experience and provide cutting-edge solutions to our clients.",
      slogan:
        "Empower Your Data with Advanced Analytics: Dive Deep with Custom Dashboards and Real-Time Insights, All Seamlessly Integrated with Google BI",
      date: "November 2022",
      company: "Coconut Software",
      team: "Product Manager, UX Designer, Senior Developer, Intermediate Developer, Junior Developer",
      technologies: "React, PHP, Laravel, JavaScript, LookML, Figma",
      url: "https://github.com/ae-perez/aevp-portfolio/blob/main/src/images/advancedAnalytics.png?raw=true",
    },
    {
      title: "Design System",
      img: DesignImage,
      summary:
        '<h3 class="subheading">Introduction to the ‘Tab’ Component</h3>' +
        "By integrating an innovative 'Tab' component enriched Coconut Software's design system.It was a strategic enhancement designed to refine navigation efficiency within our core application. This addition was meticulously developed to target specific pages where user experience could be notably improved." +
        "</br></br>" +
        '<h3 class="subheading">Enhancing User Experience and Navigation</h3>' +
        "The new 'Tab' component not only streamlines the navigation process but also elevates the overall user interface by introducing more intuitive interaction patterns. This enhancement plays a crucial role in simplifying users' access to essential information and functionalities, thereby reducing navigation time and enhancing user satisfaction. The thoughtful design and seamless integration of this component demonstrate our commitment to continually improving our product's usability and aesthetic appeal.",
      slogan:
        "Enhancing Your Navigation Experience: Introducing the Innovative 'Tab' Component for Streamlined Access and Improved Usability",
      date: "June 2022",
      company: "Coconut Software",
      team: "Product Manager, UX Designer, Senior Developer, Junior Developer",
      technologies: "React, JavaScript, TypeScript, Figma",
    },
    {
      title: "Rock, Paper Scissors",
      img: RockPaperScissorsImage,
      summary:
        "Developed an engaging and visually captivating Rock Paper Scissors game, designed to provide a delightful and entertaining gameplay experience. This game combines intuitive mechanics with striking aesthetics for hours of enjoyable play.",
      slogan:
        "Clash of Classics: Dive into the Ultimate Rock Paper Scissors Battle – Where Strategy Meets Style!",
      date: "July 2021",
      company: "Independent Contributor",
      team: "Independent Contributor",
      technologies: "HTML, CSS, JavaScript",
      url: "https://rockpaperscissors-sage.vercel.app/",
    },
    {
      title: "D3 Graph",
      img: D3Image,
      summary:
        "Designed a sophisticated D3 graph using d3.js, offering a dynamic tool for calculating potential earnings and savings from funds, ETFs, and personal savings over several years. This graph provides an intuitive and visually engaging way to project financial growth and investment outcomes.",
      slogan:
        "Visualize Your Future Wealth: Explore Potential Earnings with Our D3 Graph – Where Finance Meets Innovation!",
      date: "November 2019",
      company: "Independent Contributor",
      team: "Independent Contributor",
      technologies: "HTML, D3.js",
      url: "https://firstd3graph.vercel.app/",
    },
    {
      title: "Mario Animations",
      img: MarioAnimationImage,
      summary:
        "Crafted a series of CSS animations to bring beloved Mario characters to life, enabling them to dash across your computer screen in a lively display. This creative project encapsulates the essence of these iconic figures, adding a playful and dynamic touch to your digital experience.",
      slogan:
        "Leap into Fun: Watch Mario Characters Spring to Life with Vibrant CSS Animations – A Digital Dash of Nostalgia!",
      date: "September 2019",
      company: "Independent Contributor",
      team: "Independent Contributor",
      technologies: "HTML, CSS",
      url: "https://mariokartpage.perezalejandra.vercel.app/",
    },
    {
      title: "Budget App",
      img: BudgetImage,
      summary:
        "Developed a comprehensive budgeting app designed to help users efficiently manage their finances. This intuitive tool allows for the meticulous tracking of income and expenses, offering a clear and consolidated view of the user's monthly financial status. It simplifies financial planning, ensuring a better understanding and control of one's budget.",
      slogan:
        "Master Your Money: Discover the Power of Our Budgeting App for Ultimate Financial Control and Clarity!",
      date: "August 2019",
      company: "Independent Contributor",
      team: "Independent Contributor",
      technologies: "HTML, CSS, JavaScript",
      url: "https://budgetapp-mdmfwlxnl.now.sh/",
    },
  ];

  // Effect hook for updating the hasScrolled state
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
      } else {
        setHasScrolled(false);
      }
    };

    console.log(hasScrolled);

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup function
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleModalToggle = () => {
    setIsBlurred(!isBlurred);
  };

  const handleCardClick = (card) => {
    setSelectedProject(card);
  };

  const nextItem = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % cardData.length);
  };

  const prevItem = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? cardData.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      const timer = setTimeout(() => {
        setShowContent(true);
        localStorage.setItem("hasVisited", "true");
      }, 2000); // 10 seconds for testing

      return () => clearTimeout(timer);
    } else {
      setShowContent(true);
    }
  }, []);

  const scrollToWorkExperience = () => {
    // Using document.querySelector to get the section element by ID
    const workExperienceElement = document.querySelector("#featured-work");

    // Using scrollIntoView to scroll to the section
    workExperienceElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-almond phone:p-2">
      <Navigation />
      {isBlurred && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-30"></div>
      )}
      {showContent ? (
        <main className={isBlurred ? "content blur-md" : "content"}>
          <div>
            {/* title and intro */}
            <div className="h-screen flex flex-col justify-center items-center phone:h-svh">
              <h1 className="text-majorelleBlue-500 mb-2 mt-44 text-center text-8xl font-extrabold tracking-wide phone:text-4xl tablet:mb-4 tablet:text-7xl laptop:mb-4">
                Hi, I'm Alé.
              </h1>
              <div className="text-center mt-2 font-extrabold text-8xl [text-wrap:balance] bg-clip-text phone:hidden tablet:hidden laptop:flex laptop:items-center">
                <span className="text-majorelleBlue-500">A </span>
                <span className="text-majorelleBlue-500 inline-flex flex-col h-[calc(theme(fontSize.9xl)*theme(lineHeight.tight))] tablet:h-[calc(theme(fontSize.8xl)*theme(lineHeight.tight))] laptop:h-[calc(theme(fontSize.8xl)*theme(lineHeight.tight))] desktop:h-[calc(theme(fontSize.8xl)*theme(lineHeight.tight))] xxl:h-[calc(theme(fontSize.8xl)*theme(lineHeight.tight))] overflow-hidden">
                  <ul className="block animate-text-slide leading-tight [&_li]:block laptop:flex laptop:flex-col laptop:items-center laptop:inline-block">
                    <li className="bg-blue rounded-full laptop:px-6 desktop:px-10 xxl:px-10">
                      full stack
                    </li>
                    <li className="bg-aquamarine rounded-full laptop:px-6">
                      front end
                    </li>
                    <li className="bg-melon rounded-full laptop:px-6">
                      back end
                    </li>
                    <li className="bg-peach rounded-full text-center laptop:px-6">
                      software
                    </li>
                    <li
                      className="bg-blue rounded-full laptop:px-6"
                      aria-hidden="true"
                    >
                      full stack
                    </li>
                  </ul>
                </span>{" "}
                <span className=" text-majorelleBlue-500">developer.</span>
              </div>
              {/* for phone/tablet/laptop screens */}
              <div className="m-10 text-center text-majorelleBlue-500 font-extrabold phone:text-4xl tablet:text-7xl laptop:hidden desktop:hidden xxl:hidden">
                A{" "}
                <span className="bg-blue rounded-full px-4 mb-2 inline-flex items-center justify-center phone:px-2 tablet:px-6 tablet:py-2 laptop:bg-aquamarine laptop:py-2">
                  Toronto
                </span>
                , based{" "}
                <span className="bg-peach rounded-full px-4 mb-2 inline-flex items-center justify-centerphone:px-2 tablet:px-6 tablet:py-2 laptop:bg-aquamarine laptop:py-2">
                  developer
                </span>
                .
              </div>
              <p className="text-majorelleBlue-500 text-4xl my-10 phone:text-base phone:text-center tablet:text-2xl laptop:text-2xl">
                I'm passionate about crafting digital magic ⭐
              </p>
            </div>
            {/* bouncing arrow */}
            {!hasScrolled && (
              <div
                onClick={scrollToWorkExperience}
                className="absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-96 z-20 phone:top-1/3 tablet:top-1/3 laptop:top-1/3 desktop:top-1/3"
              >
                <span>
                  <svg
                    className="animate-bounce w-32 h-32 text-majorelleBlue-500 tablet:w-24 tablet:h-24 phone:w-14 phone:h-14"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            )}
            <p className="text-majorelleBlue-500 text-4xl italic phone:my-40 phone:text-base phone:text-center tablet:hidden laptop:hidden desktop:hidden xxl:hidden">
              Psssst. You can't access my portfolio on Mobile yet. Shoot me an
              email or check my work out on a larger screen.
            </p>
            {/* demo work */}
            <section id="featured-work" className="phone:hidden">
              <div>
                <div className="text-majorelleBlue-500 tablet:mx-14">
                  <h1 className="mb-4	text-center font-extrabold leading-none tracking-tight tablet:text-7xl laptop:text-7xl desktop:text-8xl">
                    Featured Work.
                  </h1>
                  <p className="mb-6 text-center text-lg font-normal tablet:text-justify laptop:text-2xl desktop:text-2xl desktop:px-48">
                    A curated showcase of my expertise as a developer. Below you
                    will find a glimpse into the breadth of my skills and
                    creativity, offering a preview of the innovative solutions
                    and engaging experiences I am capable of crafting.
                  </p>
                </div>
                {/* Carousel Component */}
                <div className="relative w-full rounded-lg">
                  <div
                    className="relative w-full h-[600px] overflow-hidden tablet:h-[410px] laptop:h-[480px]"
                    data-carousel="slide"
                  >
                    {cardData.map((card, index) => (
                      <div
                        key={index}
                        className={`absolute inset-0 bg-majorelleBlue-500 transition-transform duration-300 ease-in-out ${
                          index === activeIndex
                            ? "translate-x-0"
                            : index < activeIndex
                            ? "-translate-x-full"
                            : "translate-x-full"
                        } tablet:px-16`}
                        style={{ zIndex: index === activeIndex ? "10" : "0" }}
                      >
                        <Card
                          card={card}
                          onCardClick={() => handleCardClick(card)}
                          onModalToggle={handleModalToggle}
                          colorClass={colors[index % colors.length]}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="absolute z-30 flex justify-center -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
                    {cardData.map((_, index) => (
                      <button
                        key={index}
                        type="button"
                        className={`w-3 h-3 rounded-full ${
                          index === activeIndex
                            ? "bg-majorelleBlue-100"
                            : "bg-jasmine-500"
                        }`}
                        aria-current={index === activeIndex ? "true" : "false"}
                        aria-label={`Slide ${index + 1}`}
                        onClick={() => setActiveIndex(index)} // Assume setActiveIndex is the method to update the active index in your state
                      />
                    ))}
                  </div>
                  <button
                    onClick={prevItem}
                    className="absolute animate-bounce top-1/2 left-60 transform -translate-y-1/2 z-20 tablet:top-1/2 tablet:left-2 laptop:left-10 desktop:left-10 xxl:left-[600px]"
                  >
                    <span className="inline-flex items-center justify-center w-10 h-10 rounded-full">
                      <svg
                        className="w-32 h-32 text-gray-800 rtl:rotate-180 tablet:w-10 tablet:h-10"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 1 1 5l4 4"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  </button>
                  <button
                    onClick={nextItem}
                    className="absolute animate-bounce top-1/2 right-60 transform -translate-y-1/2 z-20 tablet:top-1/2 tablet:right-1 laptop:right-11 desktop:right-11 xxl:right-[600px]"
                  >
                    <span className="inline-flex items-center justify-center w-10 h-10 rounded-full">
                      <svg
                        className="w-32 h-32 text-gray-800 rtl:rotate-180 tablet:w-10 tablet:h-10"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <span className="sr-only">Next</span>
                    </span>
                  </button>
                </div>
              </div>
            </section>
          </div>
        </main>
      ) : (
        <div
          role="status"
          className="flex flex-row min-h-screen justify-center items-center"
        >
          <svg
            aria-hidden="true"
            className="w-48 h-48 text-gray-200 animate-spin dark:text-yellow-100 fill-yellow-400"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default HomePage;
