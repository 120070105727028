import React from "react";

const Experience = () => {
  return (
    <div className="mt-10 ml-10 text-majorelleBlue-500 tablet:ml-0">
      <ol className="relative laptop:px-4">
        <li className="pr-2 pl-20 pb-10 border-s border-majorelleBlue-700 tablet:ml-20">
          <span
            className="absolute flex items-center justify-center w-14 h-14 bg-aquamarine rounded-full -start-8 ring-4 ring-majorelleBlue-500 tablet:start-14 tablet:-top-2 tablet:w-12 tablet:h-12 laptop:-start-4"
            style={{ boxShadow: "2px 2px #000000" }}
          >
            <svg
              className="w-7 h-7"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 16"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
            </svg>
          </span>
          <h3 className="font-medium text-lg leading-tight tablet:font-bold tablet:text-base tablet:text-right xxl:text-2xl">
            Coconut Software
          </h3>
          <p className="text-lg font-extralight	 tablet:text-base tablet:text-right xxl:text-2xl">
            Full-Stack Developer
          </p>
          <p className="text-lg  font-extralight tablet:text-right xxl:text-2xl">
            January 2022 - October 2023
          </p>
          <br />
          <p className="text-lg text-justify tablet:text-base tablet:text-right xxl:text-2xl">
            At Coconut Software, I transformed into the tech virtuoso, embracing
            Test-Driven Development to dramatically reduce defects. Armed with
            my React toolkit, I sculpted UI elegance, significantly enhancing
            design coherence. Venturing into the realms of Figma and Looker BI,
            I crafted captivating user experiences and profound data landscapes.
            A steadfast sentinel of code, ensuring each innovation launched was
            a paragon of quality.
          </p>
        </li>
        <li className="pr-2 pl-20 pb-10 border-s border-majorelleBlue-700 tablet:ml-20">
          <span
            className="absolute flex items-center justify-center w-14 h-14 bg-melon rounded-full -start-8 ring-4 ring-majorelleBlue-500 tablet:start-14 tablet:top-[270px] tablet:w-12 tablet:h-12 laptop:-start-4"
            style={{ boxShadow: "2px 2px #000000" }}
          >
            <svg
              className="w-7 h-7"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 16"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
            </svg>
          </span>
          <h3 className="font-medium text-lg leading-tight tablet:font-bold tablet:text-base tablet:text-right xxl:text-2xl">
            Tata Consultancy Services
          </h3>
          <p className="text-lg font-extralight	tablet:text-base tablet:text-right xxl:text-2xl">
            Software Developer
          </p>
          <p className="text-lg font-extralight	tablet:text-right xxl:text-2xl">
            July 2020 - January 2022
          </p>
          <br />
          <p className="text-lg text-justify tablet:text-base tablet:text-right xxl:text-2xl">
            In the tech orchestra at Tata Consultancy Services, I played the
            role of harmonizing with a dynamic ensemble to orchestrate a
            seamless migration to cutting-edge infrastructure. Commanding Blue
            Prism automation, I navigated our brisk environment with agility,
            ensuring our RPA bots moved with unparalleled precision. Daily, I
            dove into data lakes, fishing out insights and mending the nets of
            our software ecosystem. My opus? A collection of meticulously
            composed test scores and knowledge symphonies, echoing through the
            halls of innovation.
          </p>
        </li>
        <li className="pr-2 pl-20 pb-10 border-s border-majorelleBlue-700 tablet:ml-20">
          <span
            className="absolute flex items-center justify-center w-14 h-14 bg-peach rounded-full -start-8 ring-4 ring-majorelleBlue-500 tablet:start-14 tablet:top-[600px] tablet:w-12 tablet:h-12 laptop:-start-4"
            style={{ boxShadow: "2px 2px #000000" }}
          >
            <svg
              className="w-7 h-7"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 16"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
            </svg>
          </span>
          <h3 className="font-medium text-lg leading-tight tablet:font-bold tablet:text-base tablet:text-right xxl:text-2xl">
            Homebound
          </h3>
          <p className="text-lg font-extralight	tablet:text-base tablet:text-right xxl:text-2xl">
            Front-end Developer
          </p>
          <p className="text-lg font-extralight	tablet:text-right xxl:text-2xl">
            July 2019 - April 2020
          </p>
          <br />
          <p className="text-lg text-justify tablet:text-base tablet:text-right xxl:text-2xl">
            {/* I couldn't think of a fun narrative for this one */}
            {/* At Homebound, I led a pivotal web redesign project aimed at
            improving accessibility to high-growth real estate funds. My role
            involved steering a multidisciplinary team through the complexities
            of technical design and development, resulting in enhanced user
            satisfaction and more efficient project timelines. By addressing and
            solving technical challenges, we ensured the application's high
            performance and scalability. Collaborating closely with designers,
            engineers, and product managers, we successfully implemented 7
            Zeppelin UI designs using advanced web technologies such as HTML,
            CSS, ES6+, and D3.js, significantly contributing to our digital
            platform's innovation and user experience. */}
            In the bustling world of Homebound, I embarked on a daring quest as
            the navigator of a crew on a mission to transform the vast digital
            landscape of real estate funds. Our adventure led us through the
            treacherous terrains of technical design and development, where we
            battled against the clock to create a more accessible realm for our
            fellow seekers of high-growth treasures. With a band of skilled
            artisans, wizards of code, and visionary pathfinders by my side, we
            conquered the challenges that lay before us. Together, we crafted
            magic with 7 Zeppelin UI designs, wielding the powerful artifacts of
            HTML, CSS, ES6+, and D3.js, to bring forth a digital domain where
            satisfaction soared and timelines shrank. Our tale is one of
            innovation, collaboration, and triumph in the face of the digital
            unknown.
          </p>
        </li>
        <li className="pr-2 pl-20 pb-10 tablet:ml-20">
          <span
            className="absolute flex items-center justify-center w-14 h-14 bg-blue rounded-full -start-8 ring-4 ring-majorelleBlue-500 tablet:start-14 tablet:top-[990px] tablet:w-12 tablet:h-12 laptop:-start-4"
            style={{ boxShadow: "2px 2px #000000" }}
          >
            <svg
              className="w-7 h-7"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 16"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z" />
            </svg>
          </span>
          <h3 className="font-medium text-lg leading-tight tablet:font-bold tablet:text-base tablet:text-right xxl:text-2xl">
            Ontario Ministry of Children, Community and Social Services
          </h3>
          <p className="text-lg font-extralight	tablet:text-base tablet:text-right xxl:text-2xl">
            Junior Software Developer
          </p>
          <p className="text-lg font-extralight	tablet:text-right xxl:text-2xl">
            May 2018 - April 2019
          </p>
          <br />
          <p className="text-lg text-justify tablet:text-base tablet:text-right xxl:text-2xl">
            At the Children's Aid Society, I orchestrated a digital expedition,
            leading the great migration of 10 databases to the new Children’s
            Protection Information Network. As the chief data detective, I
            scrutinized technical manuscripts, enhancing their truthfulness and
            streamlining communication with quality maestros to carve down
            document discrepancies. In the automation labyrinth, I unearthed new
            puzzles, weaving through VBA code and casting precise test scripts
            to clear the path ahead. Testing the mettle of our digital fabric
            through integration, systems, and regression trials, I fueled a
            surge in our collective coding crusade, propelling us to new heights
            of productivity.
          </p>
          <br />
        </li>
      </ol>
    </div>
  );
};

export default Experience;
