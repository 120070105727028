import React from "react";
import CardImg from "../images/joshua-aragon-BMnhuwFYr7w-unsplash.jpg";
import ReadMore from "./ReadMore";

const Card = ({ onModalToggle, onCardClick, card, colorClass }) => {
  const imageSrc = card.img || CardImg;

  return (
    <div
      onClick={onCardClick}
      style={{ boxShadow: "15px 12px #000000" }}
      className={`mx-auto my-10 max-w-screen-lg rounded-full ${colorClass} flex flex-row items-center laptop:max-w-screen-md`}
    >
      <img
        className="rounded-full tablet:w-80 tablet:h-80 laptop:w-96 laptop:h-96"
        src={imageSrc}
        alt=""
      />

      <div className="p-5 flex flex-col justify-center text-majorelleBlue-700">
        <h5 className="mb-2 text-5xl font-bold tracking-tight tablet:text-4xl">
          {card.title}
        </h5>
        <p className="w-4/5 mb-6 tablet:text-xs tablet:w-64">{card.slogan}</p>
        <ReadMore onModalToggle={onModalToggle} card={card} />
      </div>
    </div>
  );
};

export default Card;
